/* eslint-disable default-case */
import React from 'react';
import * as Api from '../services/api/common-api.js'
import DidoDrawer from '../components/Drawer.js'
import {GreenButton} from '../components/GreenButton.js'
import { withSnackbar } from 'notistack';
import { connect } from "react-redux";
import actions from "../actions.js";
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { Button } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Grid, Paper, styled, Typography, Container } from '@mui/material';
import Markdown from 'react-markdown'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SNACK_SUCCESS, FREEMIUM_SIGN_UP_TEXT } from './../constants/common';

const ActiveTile = styled(Paper)(({ theme }) => ({    
    height: '9vh',
    verticalAlign: 'middle',
    background: '#fff',
    width:'100%',
    overflow: 'clip', 
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    alignContent: 'center',    
    border: '1px solid #ccc',
    '&:hover': {
        background: '#ccc'
    },
    marginTop: '0.5vw',
    //marginBottom: '15px',
}));

class AssistantPage extends React.Component {    
    

    constructor(props) {
        super(props);
        this.state = { tiles: [], dialogOpen: false, loading : false, assistantMessage: null, isRunCancelled: false};
    }

    componentDidMount() {
        Api.getTiles().then(res => {
            this.setState({ tiles: res.data});
        })
    }

    handleTileClick = (tile) => {
        // this.appendNewMessage({MessageType : MESSAGE_TYPE_HUMAN, Message: tile.Name})
        this.setState({ loading: true, isRunCancelled: false, loadingMsg: 'De assistent analyseert de data van de meting'});

        const timer = setInterval(() => {            
            const state = this.state;
            this.setState({ loadingMsg: this.state.loadingMsg + ' .' });
          }, 100);

        // this.openBlankLoadingMessage();
         Api.sendTileMessage({
             ClickedTileGuid: tile.Id,
             TeamGuid: this.props.currentTeam
         })
        .then((res) => {
            this.setState({assistantMessage: res.data.Message})
            //this.writeToLoadingMessage(res.data.Message, res.data.Tiles)
        })
        .catch(err => { if (this.state.isRunCancelled) Api.logger.errorWithSnackbar(`Error while assistant requesting, please contact support`, this, err)})
        .finally(() => {
            this.setState({ loading: false});
            clearInterval(timer);
        });
    }

    onCancelMessageClick = () => {
        Api.cancelLastRun().then(() => {            
            this.setState({ assistantMessage: "Run was canceled", isRunCancelled: true});
        })
        .catch(err => Api.logger.errorWithSnackbar(`Error while request canceling, please contact support`, this, err))
        .finally(() => this.setState({ loading: false}));;
    }

    handleClipboardClick = async () => {
        navigator.clipboard.writeText(this.state.assistantMessage).then(() => {
            this.props.enqueueSnackbar("Text has been copied to the clipboard", SNACK_SUCCESS);
        });
    }

    render() {
        var buttonStyle = {};
        if (this.state.loading) {
            buttonStyle = {
                pointerEvents: 'none',
                background: '#ccc'
            }
        }
        return (
            <div style={{ transform: 'translateZ(0px)', flexGrow: 1, }} >
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle='Team assistent' />

                {/* <DidoHeart loading={this.state.loading} /> */}

                <Grid container style={{ marginTop: '75px', marginLeft: '40px', width: '95vw' }}>
                    <Grid item xs={3} sx={{ overflowY: 'auto', maxHeight: '90vh', marginTop: '0.5vw'}}
                    // sx={{ display: "flex" }}
                    >
                        {
                            this.state.tiles.map(tile => {
                                return (
                                    <ActiveTile onClick={() => this.handleTileClick(tile)} elevation={1} style={buttonStyle}>
                                        <Grid container>
                                            <Grid item xs={2} sx={{ textAlign: 'center', verticalAlign: 'middle', alignContent: 'center' }}>
                                                {tile.Avatar ?
                                                    <img src={tile.Avatar} width={40} height={40} alt="" style={{ marginTop: '0.5vh' }} /> : // sx={{ width: 32, height: 32 }} 
                                                    <AutoFixHighIcon sx={{ width: 24, height: 24 }} />
                                                }
                                            </Grid>
                                            <Grid item xs={10} alignContent={'center'} >
                                                <Typography>{tile.Name}</Typography></Grid>
                                        </Grid>
                                    </ActiveTile>
                                );
                            })
                        }
                    </Grid>
                    <Grid item xs={9}>
                        <Paper sx={{ overflowY: 'auto', height: '85vh', padding: '1vw', margin: '1vw', border: '1px solid #ccc' }}>
                            {
                                this.state.loading ?
                                    <div>
                                        {this.state.loadingMsg}
                                        <span className={'blink'}>{String.fromCharCode(9632)}</span>
                                        <div style={{ marginTop: '3vh' }}><Button
                                            variant="contained"
                                            startIcon={<CancelScheduleSendIcon />}
                                            onClick={this.onCancelMessageClick}
                                            style={{ backgroundColor: "#C15F7E" }}
                                        >
                                            Stop
                                        </Button>
                                        </div>
                                    </div> :
                                    this.state.assistantMessage ?
                                        <Grid container>
                                            <Grid item xs={10}></Grid>
                                            <Grid item xs={2}>
                                                <GreenButton 
                                                startIcon={<ContentCopyIcon/>} 
                                                sx={{width: 'fit-content'}}
                                                onClick={this.handleClipboardClick}
                                                >
                                                    Copy to clipboard</GreenButton>
                                                    </Grid>
                                            <Grid item xs={12}><Markdown>{this.state.assistantMessage}</Markdown></Grid>
                                        </Grid>
                                        :
                                        <div>Click on the left-side buttons to get messages from assistant</div>
                            }
                        </Paper>
                    </Grid>
                </Grid>
                {/* </Container> */}

            </div>);
    }
}

export default connect((state)=>{    
    return {        
        currentOrg: state.didoReducer.currentOrg,
        currentTeam: state.didoReducer.currentTeam
    }}, actions)(withSnackbar(AssistantPage));