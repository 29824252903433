import React from 'react';
import * as Api from '../services/api/common-api'
import DidoDrawer from '../components/Drawer'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LocationDropDown from './../components/LocationDropDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import  Paper  from '@mui/material/Paper';
import {DragDropContext,Droppable} from 'react-beautiful-dnd'
import  Modal  from '@mui/material/Modal';
import { Heart } from 'react-spinners-css';
import KanbanTaskDialog from './Dialog/KanbanTaskDialog';
import KanbanTaskHistoryDialog from './Dialog/KanbanTaskHistoryDialog';
import { withSnackbar } from 'notistack';
import { SNACK_SUCCESS } from './../constants/common';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { KanbanTaskCardDraggable } from '../components/KanbanTaskDraggable';


class KanbanPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {  swimlanes: [], dialogOpen: false, loading: false, currentLocationGuid:'', currentItem:null, themes: [], teams: [], 
        isDialogOpenHistory: false, history: [], historyOfTask: null, width: window.innerWidth };
    }

    componentDidMount() {
        if (this.props.currentUser.BelongsToTeam != null && this.props.currentUser.BelongsToTeam.LocationId != null) {            
            this.onLocationSelected(this.props.currentUser.BelongsToTeam.LocationId)
        }
    }

    componentWillMount() {       
        window.addEventListener('resize', this.onWindowSizeChange);
    }

    onWindowSizeChange = () => {
        this.setState({width: window.innerWidth });
    };

    getThemes = () => {
        Api.getThemes()
            .then(res => this.setState({ themes: res.data }))
            .catch(err =>Api.logger.errorWithSnackbar(`Fout bij ophalen van thema's`, this, err));
    };

    onLocationSelected = async (locationGuid) => {
        await this.getKanbanData(locationGuid);
        this.loadTeams()
    }
    

    loadTeams = () => {
        Api.getTeamsByLocation(this.state.currentLocationGuid)
        .then(res => this.setState({ teams: res.data }))
        .catch(err =>Api.logger.errorWithSnackbar(`Fout bij het ophalen van teams op locatie`, this, err));
    }

    getKanbanData = async (locationGuid) => {
        await this.setState({ loading: true})
        if (locationGuid)
            await this.setState({ currentLocationGuid: locationGuid })

        Api.getKanbanData(this.state.currentLocationGuid)
        .then(res => this.setState({swimlanes: res.data, loading: false}))
        .catch(err =>Api.logger.errorWithSnackbar(`Error while getKanbanData`, this, err));
    
    }
        
    getKanbanBoardStyle = (isDraggingOver) => {
        return {
             background: isDraggingOver? '#d1f5c9': '#EEE',
              height: '60vh',
               overflowY: 'auto'             }
    }

    onDragEnd = async (result) => {
        const { source, destination } = result;

        console.log("dragEnd:" + JSON.stringify(result));

        if (!destination) { // dropped outside the list
            return;
        }

        if (source.droppableId === destination.droppableId) {
            // update on client:
            let board = this.state.swimlanes.flatMap(sw => sw.Boards).find( b => b.Id === source.droppableId);
            const [removed] = board.Tasks.splice(source.index, 1);
            board.Tasks.splice(destination.index,0, removed);

            // update on server
            let reorderDto = {
                BoardGuid: source.droppableId,
                SourceIndex: source.index,
                DestIndex: destination.index
            };
            
            Api.reorderTasks(reorderDto)
            .then(res => {
                if (res.data !== true) {
                    this.props.enqueueSnackbar("Task has been reordered", SNACK_SUCCESS);
                    this.getKanbanData()}
            } )
            .catch(err =>Api.logger.errorWithSnackbar(`Error while reorderTask `, this, err));

        }
        else {
            let boardSource = this.state.swimlanes.flatMap(sw => sw.Boards).find( b => b.Id === source.droppableId);
            let boardDest = this.state.swimlanes.flatMap(sw => sw.Boards).find( b => b.Id === destination.droppableId);
            const [removed] = boardSource.Tasks.splice(source.index, 1)
            boardDest.Tasks.splice(destination.index, 0, removed)

            const moveDto = {
                SourceBoardGuid: source.droppableId,
                DestBoardGuid: destination.droppableId,
                SourceIndex: source.index,
                DestIndex: destination.index
            };
            Api.moveTask(moveDto)
            .then(res =>{if (res.data !== true) {
                this.props.enqueueSnackbar("Taak is verplaatst", SNACK_SUCCESS);
                this.getKanbanData()
            }
            })
            .catch(err =>Api.logger.errorWithSnackbar(`Error while moveTask`, this, err));


        }
    }
   
    render() {
        console.log(this.state.swimlanes)
        return (
            <div>
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle="Leren en verbeteren" onAddFabClick={(e) => this.showTaskDialog()}  />
                {
                    this.props.currentUser.BelongsToTeam ?
                        '' :
                        <AppBar position="fixed" style={{ top: '56px', background: 'white' }}>
                            <Toolbar style={{ background: 'white' }} position="fixed">
                                <LocationDropDown onLocationSelected={(locationGuid) => this.onLocationSelected(locationGuid)} />
                            </Toolbar>
                        </AppBar>
                }
                
                <Modal open={this.state.loading} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div align="center" style={{ outline: 'none' }}><Heart color="#65F2C8" size={100} /></div>
                </Modal>
                <Container maxWidth="xl" style={{marginTop:this.props.currentUser.BelongsToTeam ?'75px' : '140px' }}>
                    {
                        this.state.swimlanes.map(swimlane => {
                            if (this.props.currentUser.BelongsToTeam && this.props.currentUser.BelongsToTeam.Id !== swimlane.OwnerTeam.Id)
                                return null;
                            return (
                                <Accordion key={swimlane.OwnerTeam.Name}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant='h6'> {swimlane.OwnerTeam.Name}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DragDropContext onDragEnd={this.onDragEnd} key={swimlane.OwnerTeam.Name}>
                                            <Grid container spacing={3}>
                                                {
                                                    swimlane.Boards.map(board => {
                                                        var xs = (this.state.width < 700 ) ? 12: 4;

                                                        return (
                                                            
                                                                
                                                                <Droppable droppableId={board.Id} key={board.Id}>
                                                                    {(provided, snapshot) => (
                                                                        <Grid item xs={xs} key={board.Id} {...provided.droppableProps} ref={provided.innerRef}>
                                                                            <Grid container>
                                                                                <Grid item xs={8}><Typography variant='subtitle1' style={{ fontWeight: 'bold' }}> {board.Name}</Typography></Grid>
                                                                                <Grid item xs={4} style={{ textAlign: 'right', verticalAlign: 'sub', paddingRight: '5px', paddingTop: '5px' }}><Typography variant='caption'>{board.Tasks.length} Actieve taken</Typography></Grid>
                                                                            </Grid>
                                                                            <Paper style={this.getKanbanBoardStyle(snapshot.isDraggingOver)} elevation={0}>
                                                                                {
                                                                                    
                                                                                    board.Tasks.map((task, index) => {
                                                                                        
                                                                                        return (
                                                                                            <KanbanTaskCardDraggable
                                                                                                key={task.Id}
                                                                                                task={task}
                                                                                                index={index}
                                                                                                board={board}
                                                                                                showTaskDialog={this.showTaskDialog}
                                                                                                onTaskDelete={this.onTaskDelete}
                                                                                                onShowHistoryDialog={this.onShowHistoryDialog}
                                                                                            />
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </Paper>
                                                                            {provided.placeholder}
                                                                        </Grid>
                                                                    )}
                                                                </Droppable>
                                                            
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </DragDropContext>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                    <KanbanTaskDialog
                    open={this.state.dialogOpen} 
                    dialogAction={this.dialogAction}
                    currentItem={this.state.currentItem}
                    themes = {this.state.themes}
                    teams = {this.state.teams}
                    />

                    <KanbanTaskHistoryDialog
                    open={this.state.isDialogOpenHistory} 
                    onClose={this.onCloseHistoryDialog}
                    history = {this.state.history}
                    historyOfTask = {this.state.historyOfTask}
                    />
                </Container>


            </div>);
    }

    showTaskDialog = async (task, board) => {
        if (task)
            task.Board = board
        
        this.getThemes();
        this.loadTeams();
        await this.setState({ currentItem: task, dialogOpen: true })
    };

    onShowHistoryDialog = (task) => {
        Api.getTaskHistory(task.Id).then((res) => this.setState({isDialogOpenHistory: true, history: res.data, historyOfTask: task}))
        .catch(err =>Api.logger.errorWithSnackbar(`Error while getTaskHistory`, this, err));
    }

    onCloseHistoryDialog = () => this.setState({isDialogOpenHistory: false})

    onTaskDelete = (task, board) => {
        Api.deleteTask(task.Id).then(result =>{
            this.props.enqueueSnackbar("Succesvol verwijderd", SNACK_SUCCESS);
            board.Tasks = board.Tasks.filter(t => t.Id !== task.Id);
            this.forceUpdate();
        })
        .catch(err =>Api.logger.errorWithSnackbar(`Error on delete task`, this, err));
    }

    dialogAction = (isCanceled, dialogResult) => {

        this.setState({ dialogOpen: false });
        
        if (isCanceled){
            return;
        }

        const formData = new FormData();    
        formData.append("Objective", dialogResult.objective)        
        formData.append("Theme.Id", dialogResult.selectedThemeGuid)
        formData.append("Board.Id", dialogResult.selectedBoardGuid)        
        formData.append("FormFile", dialogResult.file)
        
        let promise; 

        if (!this.state.currentItem) { // dialog for new item
            promise = Api.createTaskWithUpload(formData);
        }
        else {
            formData.append("Id", this.state.currentItem.Id);
            formData.append("Attachment", this.state.currentItem.Attachment);
            formData.append("LinkToReport", this.state.currentItem.LinkToReport)
            promise = Api.updateTaskWithUpload(formData);
        }

         promise.then((res) => { // create
            this.props.enqueueSnackbar("Wijzigingen zijn toegepast", SNACK_SUCCESS);
             if (!this.state.currentItem) {                 
                 let board = this.state.swimlanes.flatMap(sw => sw.Boards).find(b => b.Id === dialogResult.selectedBoardGuid);
                 if (board) {
                     board.Tasks.push(res.data)
                     this.forceUpdate();
                 }
                 else
                     Api.logger.errorWithSnackbar(`Board ${dialogResult.selectedBoardGuid} not found`, this, null);
             }
             else {
                 let task = this.state.currentItem;
                 
                 task.Objective = dialogResult.objective;
                 task.Attachment = res.data.Attachment;
                 if (dialogResult.selectedThemeGuid !== task.Theme.Id)
                    task.Theme = this.state.themes.find(th => th.Id === dialogResult.selectedThemeGuid) 
                 this.forceUpdate();
             }
         })
         .catch(err =>Api.logger.errorWithSnackbar(`Error while modifing task`, this, err));
    }

    
    

    
    
}

const mapStateToProps = (state) =>  ({ currentUser: state.didoReducer.currentUser })

export default connect(mapStateToProps)(withSnackbar(KanbanPage));