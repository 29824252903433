import React from 'react';
import * as Api from '../../services/api/common-api'
import { Paper, TextField, InputAdornment, Button } from '@mui/material';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import ChatMessage from './ChatMessage';
import { EMPTY_GUID, MESSAGE_TYPE_ASSISTANT, MESSAGE_TYPE_HUMAN } from '../../constants/common';


const WelcomeMessage = "Hoi, ik ben jouw assistent, waarmee kan ik je helpen?";


class ChatWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            userText: '',
            responseLoading: false,
        };
        this.myRef = React.createRef()
    }

    componentWillMount() {
        window.addEventListener('resize', this.onWindowSizeChange);
    }

    componentDidMount() {
        this.onWindowSizeChange();        
        Api.getTiles().then(res => {
            this.appendNewMessage({ Message: WelcomeMessage, MessageType: MESSAGE_TYPE_ASSISTANT, Tiles: res.data })
        })
    }

    onWindowSizeChange = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    onUserTextChanged = (event) => {
        this.setState({ userText: event.target.value })
        if (this.state.userText.length > 0) {
            this.setState({ isSendButtonAvailable: true })
        }
    }
    isMobileLayout = () => this.state.width < 700

    appendNewMessage = (msg) => {
        // if (!msg.Message && !msg.Tiles){
        //     return;
        // }
        let msgs = this.state.messages
        msgs.push(msg);
        this.setState({ messages: msgs });
    }

    onSendClick = () => {
        var chatMsg = {
            Message: this.state.userText,
            MessageType: MESSAGE_TYPE_HUMAN,
            MessageId: crypto.randomUUID()
        }
        this.appendNewMessage(chatMsg);
        this.setState({ responseLoading: true, userText: '', isSendButtonAvailable: false })
        
        this.openBlankLoadingMessage();

        Api.sendTileMessage({
            TypedMessage: this.state.userText,
            TeamGuid: this.props.currentTeam
        }).then((res) => {
            this.setState({responseLoading: false});

            this.writeToLoadingMessage(res.data.Message, res.data.Tiles);
            
            if (res.data.length > 0) {
                this.forceUpdate();
                this.executeScroll();
            }

        })
        .catch(err => Api.logger.error(`Error while sendMessage (${err})`, err));
    }

    onCancelMessageClick = () => {
        Api.cancelLastRun().then(() => {
            let msgs = this.state.messages
            msgs.slice(-1)[0].Message = "Run was canceled"
            this.setState({ messages: msgs, responseLoading: false});
        });
    }

    openBlankLoadingMessage = () => {
        if (this.state.messages.slice(-1)[0].MessageType === MESSAGE_TYPE_HUMAN) {   
            this.appendNewMessage( { Message: '', MessageType: MESSAGE_TYPE_ASSISTANT });            
        }
    }

    writeToLoadingMessage = (msg, tiles) => {
        console.log(msg)
        this.state.messages.slice(-1)[0].Message += msg;
        this.state.messages.slice(-1)[0].Tiles = tiles;
        this.forceUpdate();
    }

    // onStartNewThreadClick = () => {
    //     Api.clearThread().then(() => {
    //         this.setState({ messages: [{ Message: WelcomeMessage, MessageType: MESSAGE_TYPE_ASSISTANT }], isNewThreadAvailable: false });
    //     })
    // }

    handleTileClick = (tile) => {
        this.appendNewMessage({MessageType : MESSAGE_TYPE_HUMAN, Message: tile.Name})
        this.setState({ responseLoading: true});
        this.openBlankLoadingMessage();
        Api.sendTileMessage({
            ClickedTileGuid: tile.Id,
            TeamGuid: this.props.currentTeam
        })
        .then((res) => {            
            this.writeToLoadingMessage(res.data.Message, res.data.Tiles)
        })
        .finally(() => this.setState({ responseLoading: false}));
    }

    getMessageContainerHeight = () => {
        if (this.state.height > 900) return '68vh'
        if (this.state.height > 700) return '65vh'
        if (this.state.height > 600) return '60vh'
        if (this.state.height > 500) return '55vh'
        if (this.state.height > 400) return '50vh'
        return '40vh'
    }

    executeScroll = () => (this.myRef && this.myRef.current) ? this.myRef.current.scrollIntoView({  block: "end", inline: "nearest", behaviour: "smooth"}) : ''

    render() {
        return (
            <Paper
                style={{ right: '5vw', top: 'auto', bottom: '12vh', left: 'auto', position: 'fixed', height: '85vh', width: '65vw', border: '1px solid #999', zIndex: 'inherit' }}                
                elevation={1}>
                <Grid container style={{ padding: '20px' }}>
                    <Grid item xs={10}><Typography variant='subtitle1' style={{ fontWeight: 'bold', color: '#555', }}>Dido</Typography></Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}><IconButton onClick={this.props.onCloseClick}><CloseIcon /></IconButton></Grid>
                    <Grid item xs={12}><hr size={1}/></Grid>
                    <Grid item xs={12} style={{ height: this.getMessageContainerHeight(), overflowY: 'auto' }}   ><Grid container spacing={1}>{
                        this.state.messages.map((msg, idx) => {
                            console.log(idx)
                            console.log(this.state.messages.length)
                            console.log(this.state.messages[idx].MessageType)
                            const IsLastAssistantMessage = idx === this.state.messages.length - 1 && this.state.messages[idx].MessageType === MESSAGE_TYPE_ASSISTANT;
                           
                            return <Grid item xs={12} style={{ paddingLeft: msg.MessageType === MESSAGE_TYPE_HUMAN ? '50px' : 'auto', paddingRight: msg.MessageType === MESSAGE_TYPE_ASSISTANT ? '50px' : 'auto' }}>
                                <ChatMessage {...msg}
                                    IsLastAssistantMessage={IsLastAssistantMessage}
                                    IsResponseLoading={this.state.responseLoading}
                                    onCancelMessageClick={this.onCancelMessageClick}
                                    onTileClick={this.handleTileClick}
                                /></Grid>
                        })
                        
                    }<div><br/><br/></div><div ref={this.myRef}></div ></Grid>
                       
                    </Grid>
                    
                    <Grid xs={12} style={{ display: 'flex' }}>
                        <TextField
                            fullWidth

                            sx={{ fontSize: '10px', marginTop: '10px' }}
                            //multiline
                            required
                            value={this.state.userText}
                            label="Waarmee kan ik je helpen?"
                            //defaultValue={this.currentObjective()}
                            onChange={this.onUserTextChanged}
                            onPaste={() => this.setState({isSendButtonAvailable: true})}
                            onKeyPress={(e) => (e.key === 'Enter') ? this.onSendClick() : ''}
                            InputProps={{
                                // startAdornment:(
                                //     <IconButton onClick={this.onStartNewThreadClick} edge="end" disabled={!this.state.isNewThreadAvailable}>
                                //         <DeleteForeverIcon style={{ color: "#C15F7E" }} />
                                //     </IconButton>
                                // ),
                                endAdornment: (
                                    <InputAdornment position="end">

                                        <Button
                                            variant="contained"
                                            onClick={this.onSendClick}
                                            startIcon={<SendIcon />}
                                            style={{ backgroundColor: "#4eb798" }}
                                            disabled={!this.state.isSendButtonAvailable}
                                        >
                                            &nbsp;
                                        </Button>


                                    </InputAdornment>
                                )
                            }}

                        /></Grid>
                </Grid>
            </Paper>

        )
    }
}


const mapStateToProps = (state) => ({
    currentUser: state.didoReducer.currentUser,
    currentTeam: state.didoReducer.currentTeam,
});

export default withSnackbar(connect(mapStateToProps)(ChatWidget));