import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { MenuItem, Select, FormControlLabel, Checkbox } from '@mui/material';
import { FormRow } from './../../components/FormRow';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export class OrgDialog extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            name: '',            
            selectedSubscriptionGuid: null,
            selectedSubscription: null, 
            isChatBotEnabled: null           
        };
    }
    
    handleIsChatBotEnabled = (e) => {        
        this.props.editableItem.IsChatBotEnabled = e.target.checked;
        this.setState({ isChatBotEnabled: e.target.checked })
    }
    currentIsChatBotEnabled = () => this.state.isChatBotEnabled != null ?
        this.state.isChatBotEnabled :
        this.props.editableItem ?
            this.props.editableItem.IsChatBotEnabled :
            false;
    
    handleNameChange = (evt) => this.setState({ name: evt.currentTarget.value });
    currentName = () => this.state.name ?
        this.state.name :
        this.props.editableItem ?
            this.props.editableItem.Name :
            ''


    handleChangeSubscription = async (event) => {
        await this.setState({ selectedSubscriptionGuid: event.target.value });
    }
    currentSubscriptionGuid = () => {        
        return (this.state.selectedSubscriptionGuid) ?
        this.state.selectedSubscriptionGuid :
        (this.props.editableItem && this.props.editableItem.Subscription) ?
            this.props.editableItem.Subscription.Id :
            this.props.subscriptions.length > 0 ?
                this.props.subscriptions[0].Id :
                ""}

    handleOkClick = async () => {
        if (!this.state.selectedSubscriptionGuid) {
            await this.setState({ selectedSubscriptionGuid: this.currentSubscriptionGuid() })
        }
        if (!this.state.selectedSubscription){            
            await this.setState({ selectedSubscription : this.props.subscriptions.find(s => s.Id === this.currentSubscriptionGuid())})
        }
        if (!this.state.name){
            await this.setState({name: this.currentName()})
        }
        if (!this.state.isChatBotEnabled){
            await this.setState({isChatBotEnabled: this.currentIsChatBotEnabled()})
        }
        await this.setState({type: this.props.type});
        this.props.dialogAction(false, this.state);
        await this.setState({ selectedSubscriptionGuid: null, selectedSubscription: null, name: null, isChatBotEnabled: null})
    }

    onCancelClick = async () => {
        this.props.dialogAction(true);
        await this.setState({ selectedSubscriptionGuid: null, selectedSubscription: null, name: null, isChatBotEnabled: null })
    }

    render = () => {

        return (
            <Dialog open={this.props.open}>
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.editableItem ? "Organisatie bewerken" : "Nieuwe organisatie"}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow>
                            <TextField
                                name="Name"
                                required
                                label="Naam"
                                defaultValue={this.currentName()}
                                onChange={this.handleNameChange} />
                        </FormRow>
                        <FormRow>
                            <Select
                                fullWidth
                                value={this.currentSubscriptionGuid()}
                                onChange={(e) => this.handleChangeSubscription(e)}>
                                {this.props.subscriptions.map((subscription) => {
                                    return (
                                        <MenuItem key={subscription.Id} value={subscription.Id}>{subscription.Name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormRow>
                        <FormRow>
                        <FormControlLabel
                                control={<Checkbox checked={this.currentIsChatBotEnabled()} 
                                onChange={this.handleIsChatBotEnabled} />}
                                label="Chatbot ingeschakeld"
                            />
                        </FormRow>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleOkClick()} color="primary">OPSLAAN</Button>
                    <Button onClick={() => this.onCancelClick()} color="primary">TERUG</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
